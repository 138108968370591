import React, { useContext } from 'react';

import { Link, useParams } from 'react-router-dom';
import { CommonContext, UserContext } from '../../contexts';

const SubSideMenu = () => {
  const { groupCode } = useParams();
  const { categoryId } = useParams();
  const { categoryGroup, categories, groupCategoryRel } = useContext(CommonContext);
  const { user } = useContext(UserContext);

  const currentGroupId = categoryGroup.find((group) => group.groupCode === groupCode)?.id;

  return (
    <aside id="sidebar" className="sidebar-container sidebar-primary">
      <>
        <div className="widget-title">{categoryGroup.find((group) => group.groupCode === groupCode)?.groupName}</div>
        <div id="cssmenu">
          <ul>
            {
              // 카테고리를 가지고 온다.
              groupCategoryRel
                .filter((rel) => rel.groupId === currentGroupId)
                .map((result) => {
                  const currentCategory = categories.find((category) => category.id === result.categoryId);

                  return (
                    <>
                      {(user.level || 0) >= currentCategory.readLevel && (
                        <li key={currentCategory.id} className={parseInt(categoryId) === currentCategory.id ? 'active' : ''}>
                          <Link to={`/${currentCategory.categoryType}/${groupCode}/${currentCategory.id}/${currentCategory.categoryType === 'board' ? 'list?offset=0' : currentCategory.categoryCode}`}>
                            {currentCategory.categoryName}
                          </Link>
                        </li>
                      )}
                    </>
                  );
                })
            }
          </ul>
        </div>
      </>
    </aside>
  );
};

export default SubSideMenu;
